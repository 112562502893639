import React, {useEffect, useState} from 'react';
import './App.scss';
import {BrowserRouter, Route, Routes} from "react-router-dom";
import AuthPage from "./auth_page";
import UnauthorisedPage from "./unauthorised_page";
import {Authentication, AuthenticationContext} from "./authentication";
import {AuthenticationProtected} from "./authentication_protected";
import ListVendingMachinesPage from "./list_vending_machines_page";
import {getEmployeeUserServiceApi} from "./api";
import VendingMachineDetailsPage from "./vending_machine_details_page";
import OrderPage from "./order_page";


function App() {
    const [authentication, setAuthentication] = useState<Authentication>({
        authenticated:null,
        authentication_token:null,
        accountDetails:null
    });

    const login = (token:string)=>{
        localStorage.setItem("authToken", token);

        reloadMyInfo(token)
    }

    const reloadMyInfo = (token:string)=>{
        if (token != null){
            verifyToken(token);
        } else {
            setAuthentication({
                authenticated: false,
                authentication_token: null,
                accountDetails:null
            })
            localStorage.removeItem("authToken");
        }
    }

    const init = () => {
        let token = localStorage.getItem("authToken");
        if (token != null){
            login(token)
        } else {
            setAuthentication({
                authenticated:false,
                authentication_token:null,
                accountDetails:null
            })
        }
    }

    const verifyToken = (token:string) => {
        getEmployeeUserServiceApi().userServiceGetDetail().then((response)=>{
            if (response.data.success){
                setAuthentication({
                    authenticated: true,
                    authentication_token: token,
                    accountDetails:response.data.user_detail ?? null
                })
            } else {
                setAuthentication({
                    authenticated: false,
                    authentication_token: null,
                    accountDetails:null
                })
                localStorage.removeItem("authToken");
            }
        }).catch((reason)=>{
            setAuthentication({
                authenticated: false,
                authentication_token: null,
                accountDetails:null
            })
            localStorage.removeItem("authToken");
        })
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(init, [])

    return (
    <div>
        <BrowserRouter>
            <AuthenticationContext.Provider value={{
                authentication:authentication,
                logout:()=>{},
                login:login
            }}>
                <Routes>
                    <Route path="temporary_access/:token" element={<AuthPage/>}/>
                    <Route path="unauthorised" element={<UnauthorisedPage/>}/>
                    <Route path="/" element={<AuthenticationProtected><ListVendingMachinesPage/></AuthenticationProtected>}/>
                    <Route path="/vending_machines/:uuid" element={<AuthenticationProtected><VendingMachineDetailsPage/></AuthenticationProtected>}/>
                    <Route path="/orders/:uuid" element={<AuthenticationProtected><OrderPage/></AuthenticationProtected>}/>
                </Routes>
            </AuthenticationContext.Provider>
        </BrowserRouter>
    </div>
    );
}

export default App;
