import React, {useEffect, useState} from 'react';
import {getEmployeeOrganizationServiceApi, getEmployeeVendingMachineServiceApi} from "./api";
import VendingMachineDisplay from "./vending_machine_display";
import {PpeEmployeeVendingMachine} from "./gen-employee";

function ListVendingMachinesPage() {
    const [vendingMachines, setVendingMachines] = useState<PpeEmployeeVendingMachine[]>([]);
    const [companyLogo, setCompanyLogo] = useState("");

    const getVendingMachines = ()=>{
        getEmployeeVendingMachineServiceApi().vendingMachineServiceGetAllVendingMachines().then((response)=>{
            if (response.data.success){
                setVendingMachines(response.data.vending_machine ?? [])
            } else {
                window.alert(response.data.error_message)
            }
        }).catch((reason) => {
            window.alert("Server error. Please try again later");
        })
    }

    const getCompanyLogo = ()=>{
        getEmployeeOrganizationServiceApi().organizationServiceGetLogo().then((response)=>{
            if (response.data.success){
                setCompanyLogo(response.data.img ?? "")
            } else {
                window.alert(response.data.error_message)
            }
        }).catch((reason) => {
            window.alert("Server error. Please try again later");
        })
    }

    useEffect(getVendingMachines, [])
    useEffect(getCompanyLogo, [])

    const rows = vendingMachines.map((vendingMachine)=>{
        return <VendingMachineDisplay vendingMachine={vendingMachine} key={vendingMachine.uuid}/>
    })

    return (
        <div>
            <div className={"pt-5"}>
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col"}>
                            <p className={"text-center"}>Machines</p>
                        </div>
                    </div>
                </div>
            </div>
            <div className={"container text-center"}>
                <img src={`data:image/jpeg;base64,${companyLogo}`} className="img-fluid" alt="" id="company_logo" style={{maxWidth:"50%"}}/>
            </div>
            <div className={"pb-5 pt-4 container"}>
                {rows}
            </div>
        </div>
    );
}

export default ListVendingMachinesPage;
