import React, {Fragment, useEffect, useState} from 'react';
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {getEmployeeOrderServiceApi} from "./api";
import {OrderState} from "./models";
import SelectionWidget from "./selection_widget";
import OrderSuccessWidget from "./order_success_widget";
import CountdownWidget from "./countdown_widget";
import {PpeEmployeeOrder, PpeEmployeeOrderState} from "./gen-employee";


function OrderPage() {
    const params = useParams();
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();
    const paramsTimeout = searchParams.get('timeout') ?? "45";
    const [isOrderInProgress, setIsOrderInProgress] = useState<boolean>(true);
    const [order, setOrder] = useState<PpeEmployeeOrder | null>(null);
    let timeout = 45;  // default timeout
    try {
        const _timeout = parseInt(paramsTimeout)
        if (isNaN(_timeout)){
            console.log("false to parse timeout use the default value");
        } else {
            timeout = _timeout
        }
    } catch (e){
        console.log("false to parse timeout use the default value");
    }


    const queryOrder = () => {
        if (isOrderInProgress){
            getEmployeeOrderServiceApi().orderServiceGetOrderStatus(params.uuid ?? "").then((response)=>{
                if (response.data.success){
                    setOrder(response.data.order ?? null);
                } else {
                    window.alert(response.data.error_message)
                }
            }).catch((reason) => {
                window.alert("Server error. Please try again later")
            })
        }
    }

    const unlockOrder = () => {
        getEmployeeOrderServiceApi().orderServiceUnlockOrder(params.uuid ?? "").then((response)=>{
            if (response.data.success){
                navigate(`/`, {replace:true});
            } else {
                window.alert(response.data.error_message)
            }
        }).catch((reason)=>{
            window.alert("Server error. Please try again later")
        })
    }

    const confirmOrder = () => {
        getEmployeeOrderServiceApi().orderServiceConfirmOrder(params.uuid ?? "", {
            auto_approval:false
        }).then((response)=>{
            if (response.data.success){
            } else {
                window.alert(response.data.error_message)
            }
        }).catch((reason)=>{
            window.alert("Server error. Please try again later")
        })
    }

    const processOrderData = () => {
        if (order === null){
            return;
        }
        const failedStates:PpeEmployeeOrderState[] = [PpeEmployeeOrderState.Failure, PpeEmployeeOrderState.Aborted]
        if (failedStates.includes(order.state!)){
            setIsOrderInProgress(false);
            window.alert("Fail to dispense");
            navigate(`/`, {replace:true});
            return;
        }
        const successStates:PpeEmployeeOrderState[] = [PpeEmployeeOrderState.VendedSuccess]
        if (successStates.includes(order.state!)){
            setIsOrderInProgress(false);
        }
        const waitingStates:PpeEmployeeOrderState[] = [PpeEmployeeOrderState.PendingSelection, PpeEmployeeOrderState.PendingCustomerConfirm, PpeEmployeeOrderState.Vending]
        if (waitingStates.includes(order.state!)){
            window.setTimeout(()=>{queryOrder()}, 1000)
        }
    }

    useEffect(queryOrder, [isOrderInProgress, params.uuid]);

    useEffect(processOrderData, [order]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div>
            {(order === null || order.state === OrderState.PENDING_SELECTION) &&
                <Fragment>
                    <div className={"d-flex flex-column min-vh-100"}>
                        <div className={"pt-5"}>
                            <div className={"container"}>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        <p className={"text-center"}>Make Selection</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className={"container my-auto"}>
                            <div className={"d-flex justify-content-center align-items-center"}>
                                <CountdownWidget duration={timeout} onComplete={()=>{
                                    setIsOrderInProgress(false);
                                    unlockOrder();
                                }}/>
                            </div>
                        </div>
                        <div className={"pb-5"}>
                            <div className={"container"}>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        <p className={"text-center"}>Please make selection on vending machine</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
            {order !== null && order.state === OrderState.PENDING_CUSTOMER_CONFIRM &&
                <Fragment>
                    <div className={"d-flex flex-column min-vh-100 px-3"}>
                        <div className={"pt-5"}>
                            <div className={"container"}>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        <p className={"text-center"}>Confirmation</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <SelectionWidget selection={order.selection ?? null} vendingMachine={order.vending_machine ?? null}/>
                        <div className="d-grid gap-5 px-1">
                            <button className="btn btn-primary" type="button" onClick={confirmOrder}>Confirm</button>
                        </div>
                    </div>
                </Fragment>
            }
            {order !== null && order.state === OrderState.VENDING &&
                <Fragment>
                    <div className={"d-flex flex-column min-vh-100"}>
                        <div className={"container my-auto"}>
                            <div className={"d-flex justify-content-center align-items-center"}>
                                <p>Dispensing...</p>
                            </div>
                        </div>
                    </div>
                </Fragment>
            }
            {order !== null && order.state === OrderState.VENDED_SUCCESS &&
                <Fragment>
                    <div className={"d-flex flex-column min-vh-100 px-3"}>
                        <div className={"pt-5"}>
                            <div className={"container"}>
                                <div className={"row"}>
                                    <div className={"col"}>
                                        <p className={"text-center"}>Complete</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <OrderSuccessWidget order={order}/>
                        <div className="d-grid gap-5 px-1 py-3">
                            <button className="btn btn-primary" type="button" onClick={()=>{
                                navigate(`/`, {replace:true})
                            }}>Finish</button>
                        </div>
                    </div>
                </Fragment>
            }
        </div>
    );
}

export default OrderPage;
