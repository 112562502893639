import React from 'react';
import {CountdownCircleTimer} from "react-countdown-circle-timer";


interface Props{
    duration:number,
    onComplete:Function
}

function CountdownWidget(props:Props) {
    const renderTime = ({ remainingTime }:any) => {
        if (remainingTime === 0) {
            return <div className="timer">Timeout</div>;
        }

        return (
            <div className="timer">
                <div className="value">{remainingTime}s</div>
            </div>
        );
    };


    return (
        <CountdownCircleTimer
            rotation={'counterclockwise'}
            isPlaying
            duration={props.duration}
            colors={["#004777", "#F7B801", "#A30000", "#A30000"]}
            colorsTime={[10, 6, 3, 0]}
            onComplete={() => {
                props.onComplete();
                return { shouldRepeat: false } // repeat animation in 1.5 seconds
            }}
        >
            {renderTime}
        </CountdownCircleTimer>
    );
}

export default CountdownWidget;