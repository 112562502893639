import React from 'react';
import {PpeEmployeeOrderSelection, PpeEmployeeOrderVendingMachine} from "./gen-employee";

interface Props {
    selection: PpeEmployeeOrderSelection | null
    vendingMachine: PpeEmployeeOrderVendingMachine | null
}

function SelectionWidget(props:Props) {
    return (
        <div className="card">
            <div className={"text-center"}>
                <p>Your selection is {props.selection?.selection_number}</p>
            </div>
            <div className="card-body">
                <table className="table">
                    <tbody>
                        <tr>
                            <th scope="row">Selection</th>
                            <td>{props.selection?.selection_number}</td>
                        </tr>
                        <tr>
                            <th scope="row">Name</th>
                            <td>{props.selection?.product_name}</td>
                        </tr>
                        <tr>
                            <th scope="row">Site</th>
                            <td>{props.vendingMachine?.site}</td>
                        </tr>
                        <tr>
                            <th scope="row">Vending Machine</th>
                            <td>{props.vendingMachine?.name}</td>
                        </tr>
                        <tr>
                            <th scope="row">Business</th>
                            <td>{props.vendingMachine?.organization_name}</td>
                        </tr>
                    </tbody>
                </table>

            </div>
        </div>
    );
}

export default SelectionWidget;
