import React from 'react';
import {PpeEmployeeUserDetail} from "./gen-employee";

export interface Authentication {
    authenticated: boolean | null; // null means authentication is loading
    authentication_token: string|null;
    accountDetails:PpeEmployeeUserDetail|null;
}

export interface IAuthenticationContext {
    authentication: Authentication;

    login(token: string): void;

    logout(): void;
}

const AuthenticationContext = React.createContext<IAuthenticationContext>(
    {
        authentication: {
            authenticated: null,
            authentication_token: null,
            accountDetails:null
        },
        logout: () => {
        },
        login: (t): Promise<any> => {
            return new Promise<any>(() => {
            })
        },
    }
);

function withAuthentication(C: any) {
    return function AuthenticationComponent(props: any) {
        return (
            <AuthenticationContext.Consumer>
                {({authentication, login, logout}) =>
                    <C {...props} authentication={authentication} login={login} logout={logout}/>}
            </AuthenticationContext.Consumer>
        );
    }
}

export {AuthenticationContext, withAuthentication}
