import React, {useEffect} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {getEmployeeUserServiceApi} from "./api";
import {IAuthenticationContext, withAuthentication} from "./authentication";

interface Props extends IAuthenticationContext {

}


function AuthPage(props:Props) {
    const navigate = useNavigate()
    const params = useParams();

    const exchangeSessionToken = ()=>{
        getEmployeeUserServiceApi().userServiceExchangeSessionTokenWithTemporaryAccessToken({
            temporary_access_token:params.token
        }).then((response)=>{
            if (response.data.success){
                localStorage.setItem("authToken", response.data.token ?? "");
                props.login(localStorage.getItem("authToken") ?? "")
                navigate(`/`, {replace: true})
            } else {
                window.alert(response.data.error_message);
                window.location.href = 'https://ppe.vendingontrack.com';
            }
        }).catch((reason)=>{
            window.alert('Server Error. PLease try again later')
            window.location.href = 'https://ppe.vendingontrack.com';
        })
    }

    useEffect(exchangeSessionToken, [params.token]) // eslint-disable-line react-hooks/exhaustive-deps

    return (
        <div><p>Authorizing, please wait...</p></div>
    );
}

export default withAuthentication(AuthPage);
