export interface AccountDetails {
    name : string
    company : string
    email : string
    employee_number : string | null
    timezone : string | null
    emails : string[]
}

export interface VendingMachine {
    address:string | null
    business_address:string | null
    busy:boolean
    client_uuid:string|null
    company_external_name:string|null
    gst:string | null
    id:string
    name: string
    online:boolean
    operator_abn:string|null
    operator_contact_number:string | null
    operator_id:string
    operator_name:string | null
    photo_url:string | null
    site: string | null
    surcharge_covered_by_merchant:boolean
}

export interface VendingMachineLite{
    name: string | null
    site: string | null
    company_name: string | null
}

export interface OrderSelection{
    selection: number
    name: string | null
    photo_url: string | null
}

export enum OrderState{
    PENDING_SELECTION = 'PENDING_SELECTION',
    PENDING_CUSTOMER_CONFIRM = 'PENDING_CUSTOMER_CONFIRM',
    VENDING = 'VENDING',
    FAILURE = 'FAILURE',
    VENDED_SUCCESS = 'VENDED_SUCCESS',
    ABORTED = 'ABORTED',
}

export interface Order{
    id: number
    uuid: string
    created_at: number
    state: OrderState
    selection: OrderSelection | null
    vending_machine: VendingMachineLite
}