import React, {useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import {getEmployeeOrderServiceApi, getEmployeeVendingMachineServiceApi} from "./api";
import {PpeEmployeeVendingMachine} from "./gen-employee";

function VendingMachineDetailsPage() {
    const params = useParams();
    const navigate = useNavigate();
    const [vendingMachine, setVendingMachine] = useState<PpeEmployeeVendingMachine | null>(null);

    const findVendingMachine = () => {
        getEmployeeVendingMachineServiceApi().vendingMachineServiceSearchVendingMachine({
            uuid: params.uuid
        }).then((response)=>{
            if (response.data.success){
                setVendingMachine(response.data.vending_machine ?? null)
            } else {
                window.alert(response.data.error_message);
            }
        }).catch((reason)=>{
            window.alert("Server error. Please try again later");
        })
    }

    const startAOrder = (e:any) => {
        e.preventDefault();
        e.stopPropagation();

        getEmployeeOrderServiceApi().orderServiceStartOrder({
            vending_machine_uuid: params.uuid
        }).then((response)=>{
            if (response.data.success){
                navigate(`/orders/${response.data.order_uuid ?? ""}?timeout=${response.data.timeout ?? 45}`, {replace:true})
            } else {
                window.alert(response.data.error_message);
            }
        }).catch((reason)=>{
            window.alert("Server error. Please try again later");
        })
    }

    useEffect(findVendingMachine, [params.uuid])

    if (vendingMachine == null){
        return (<div><p>loading</p></div>)
    }

    return (
        <div className={"container mb-5"}>
            <div className={"mt-5 text-center"}>
                <p>Is this the machine?</p>
            </div>
            <div className={"backgroundSecondary machineDisplay"} style={{minHeight:400}}>
                <div className={"container text-center py-1"}>
                    <img src={vendingMachine.photo_url ?? ""} alt="" style={{maxWidth:"70%"}}/>
                </div>
                <div className={"container text-center pb-3"}>
                    <h5 className={"machineName"}>
                        {vendingMachine.name ?? ""}
                    </h5>
                    <p className={"machineAddress"}>{vendingMachine.site ?? ""}</p>
                </div>
            </div>

            <div className="d-grid gap-5 px-1">
                <button className="btn btn-primary" type="button" onClick={startAOrder}>Start</button>
            </div>


        </div>
    );
}

export default VendingMachineDetailsPage;
