import axios, {AxiosInstance} from 'axios';
import {
    Configuration,
    OrderServiceApi,
    OrganizationServiceApi,
    UserServiceApi,
    VendingMachineServiceApi
} from "./gen-employee";

interface EmployeeServicesRegistry {
    user_service: UserServiceApi | null,
    vending_machine_service: VendingMachineServiceApi | null,
    organization_service: OrganizationServiceApi | null,
    order_service: OrderServiceApi | null
}

let employeeServices: EmployeeServicesRegistry = {
    user_service: null,
    vending_machine_service: null,
    organization_service: null,
    order_service: null
}

function _getAxiosInstance(): AxiosInstance {
    const authentication_token = localStorage.getItem("authToken");

    return axios.create({
        headers: {
            'content-type': 'application/json',
            "authorization": (authentication_token === null ? "" : `Bearer ${authentication_token}`)
        }
    });
}

function getAxiosInstance(): AxiosInstance {
    // note: we can't cache axios instance here.

    return _getAxiosInstance();
}

export function getEmployeeUserServiceApi(): UserServiceApi {
    employeeServices.user_service = new UserServiceApi(getConfiguration(), window.location.origin, getAxiosInstance());

    return employeeServices.user_service;
}

export function getEmployeeVendingMachineServiceApi(): VendingMachineServiceApi {
    if (!employeeServices.vending_machine_service) {
        employeeServices.vending_machine_service = new VendingMachineServiceApi(getConfiguration(), window.location.origin, getAxiosInstance());
    }

    return employeeServices.vending_machine_service;
}

export function getEmployeeOrganizationServiceApi(): OrganizationServiceApi {
    if (!employeeServices.organization_service) {
        employeeServices.organization_service = new OrganizationServiceApi(getConfiguration(), window.location.origin, getAxiosInstance());
    }

    return employeeServices.organization_service;
}

export function getEmployeeOrderServiceApi(): OrderServiceApi {
    if (!employeeServices.order_service) {
        employeeServices.order_service = new OrderServiceApi(getConfiguration(), window.location.origin, getAxiosInstance());
    }

    return employeeServices.order_service;
}

function getConfiguration(){
    let baseURL = 'https://ppe.vendingontrack.com';

    if (window.location.hostname === 'ppe-lite-staging.vendingontrack.com') {
        baseURL = 'https://ppe-staging.vendingontrack.com';
    }

    let config = new Configuration();
    config.basePath = baseURL

    return config;
}
