import React from 'react';
import {Link} from "react-router-dom";
import {PpeEmployeeVendingMachine} from "./gen-employee";

interface Props {
    vendingMachine:PpeEmployeeVendingMachine
}

function VendingMachineDisplay(props:Props) {
    return (
        <Link to={`/vending_machines/${props.vendingMachine.uuid}`} style={{textDecoration:"none", color:"black"}}>
            <div className="card mb-2">
                <div className={"container"}>
                    <div className={"row"}>
                        <div className={"col-4"}>
                            {props.vendingMachine.photo_url !== "" && props.vendingMachine.photo_url !== null && <img className="card-img-top" alt="vending machine" src={props.vendingMachine.photo_url}/>}
                        </div>
                        <div className={"col-8 my-auto"}>
                            <div className="card-body">
                                <h5 className="card-title text-start">{props.vendingMachine.name}</h5>
                                <p className="card-text text-start">{props.vendingMachine.site}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Link>
    );
}

export default VendingMachineDisplay;
