import React from 'react';
import {IAuthenticationContext, withAuthentication} from "./authentication";
import {Navigate} from "react-router-dom";


interface Props extends IAuthenticationContext {

}

function UnauthorisedPage(props:Props) {
    if (props.authentication.authenticated) {
        return (<Navigate to="/"/>);
    }
    return (
        <div>
            <h1>Please use temporary access link to access the PPE site</h1>
        </div>
    );
}

export default withAuthentication(UnauthorisedPage);